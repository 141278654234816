import { ImageURISource } from 'react-native';
import { fill } from '@cloudinary/url-gen/actions/resize';
import { Cloudinary } from '@cloudinary/url-gen/instance/Cloudinary';
import { format } from '@cloudinary/url-gen/actions/delivery';
import { auto } from '@cloudinary/url-gen/qualifiers/format';

export const DEFAULT_BREAKPOINTS: number[] = [64, 128, 256, 512, 1024];

interface ResizeImageOptions {
  cloudinary: Cloudinary;
  imageId: string;
  size: number;
}

export function resizeImage({
  cloudinary,
  imageId,
  size,
}: ResizeImageOptions): string {
  return cloudinary
    .image(imageId)
    .delivery(format(auto()))
    .resize(
      fill()
        .width(size)
        .height(size)
        .gravity('center'),
    )
    .toURL();
}

export function generateSources(
  cloudinary: Cloudinary,
  imageId: string,
): ImageURISource[] {
  return DEFAULT_BREAKPOINTS.map(
    (size: number): ImageURISource => ({
      uri: resizeImage({ cloudinary, imageId, size }),
      width: size,
      height: size,
    }),
  );
}
