import { Cloudinary } from '@cloudinary/url-gen/instance/Cloudinary';
import { useContext } from 'react';
import ICloudinaryConfigurations from '@cloudinary/url-gen/config/interfaces/Config/ICloudinaryConfigurations';
import CloudinaryContext from './CloudinaryContext';

export function useCloudinary(): Cloudinary {
  const cloudinaryConfig = useContext<ICloudinaryConfigurations | null>(
    CloudinaryContext,
  );
  if (!cloudinaryConfig) {
    throw new Error('Missing CloudinaryContext.Provider as ancestor');
  }

  return new Cloudinary(cloudinaryConfig);
}
